import { default as indexcUksnUFdh2Meta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/[slug2]/[slug3]/index.vue?macro=true";
import { default as indexQADoB2pYpCMeta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/[slug2]/index.vue?macro=true";
import { default as index0Ixcg7N154Meta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/index.vue?macro=true";
import { default as _91slug_93nNURaoCaHOMeta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/articles/[id]/[slug].vue?macro=true";
import { default as index6IJ9M6nExgMeta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/articles/[id]/index.vue?macro=true";
import { default as indexwVNkEUnTbCMeta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/index.vue?macro=true";
import { default as pagesLK6qS0m5I9Meta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/pages.vue?macro=true";
import { default as searchpH0izZZvD5Meta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/search.vue?macro=true";
import { default as _91slug_93SVhOqCUcgcMeta } from "/var/www/vuejsexamples/subdomain-ai/src/pages/tags/[slug].vue?macro=true";
export default [
  {
    name: indexcUksnUFdh2Meta?.name ?? "slug-slug2-slug3",
    path: indexcUksnUFdh2Meta?.path ?? "/:slug()/:slug2()/:slug3()",
    meta: indexcUksnUFdh2Meta || {},
    alias: indexcUksnUFdh2Meta?.alias || [],
    redirect: indexcUksnUFdh2Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/[slug2]/[slug3]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQADoB2pYpCMeta?.name ?? "slug-slug2",
    path: indexQADoB2pYpCMeta?.path ?? "/:slug()/:slug2()",
    meta: indexQADoB2pYpCMeta || {},
    alias: indexQADoB2pYpCMeta?.alias || [],
    redirect: indexQADoB2pYpCMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/[slug2]/index.vue").then(m => m.default || m)
  },
  {
    name: index0Ixcg7N154Meta?.name ?? "slug",
    path: index0Ixcg7N154Meta?.path ?? "/:slug()",
    meta: index0Ixcg7N154Meta || {},
    alias: index0Ixcg7N154Meta?.alias || [],
    redirect: index0Ixcg7N154Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNURaoCaHOMeta?.name ?? "articles-id-slug",
    path: _91slug_93nNURaoCaHOMeta?.path ?? "/articles/:id()/:slug()",
    meta: _91slug_93nNURaoCaHOMeta || {},
    alias: _91slug_93nNURaoCaHOMeta?.alias || [],
    redirect: _91slug_93nNURaoCaHOMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/articles/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: index6IJ9M6nExgMeta?.name ?? "articles-id",
    path: index6IJ9M6nExgMeta?.path ?? "/articles/:id()",
    meta: index6IJ9M6nExgMeta || {},
    alias: index6IJ9M6nExgMeta?.alias || [],
    redirect: index6IJ9M6nExgMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/articles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwVNkEUnTbCMeta?.name ?? "index",
    path: indexwVNkEUnTbCMeta?.path ?? "/",
    meta: indexwVNkEUnTbCMeta || {},
    alias: indexwVNkEUnTbCMeta?.alias || [],
    redirect: indexwVNkEUnTbCMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pagesLK6qS0m5I9Meta?.name ?? "pages",
    path: pagesLK6qS0m5I9Meta?.path ?? "/pages",
    meta: pagesLK6qS0m5I9Meta || {},
    alias: pagesLK6qS0m5I9Meta?.alias || [],
    redirect: pagesLK6qS0m5I9Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/pages.vue").then(m => m.default || m)
  },
  {
    name: searchpH0izZZvD5Meta?.name ?? "search",
    path: searchpH0izZZvD5Meta?.path ?? "/search",
    meta: searchpH0izZZvD5Meta || {},
    alias: searchpH0izZZvD5Meta?.alias || [],
    redirect: searchpH0izZZvD5Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SVhOqCUcgcMeta?.name ?? "tags-slug",
    path: _91slug_93SVhOqCUcgcMeta?.path ?? "/tags/:slug()",
    meta: _91slug_93SVhOqCUcgcMeta || {},
    alias: _91slug_93SVhOqCUcgcMeta?.alias || [],
    redirect: _91slug_93SVhOqCUcgcMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-ai/src/pages/tags/[slug].vue").then(m => m.default || m)
  }
]